
import Enumerable from 'linq';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'



const routes: Array<RouteRecordRaw> = [

    {
        path: '/',
        name: 'home',
        component: () => import('../pages/IndexPageView.vue')

    },

  

    {
        path: '/accountopening',
        name: 'accountopening',
        component: () => import('../pages/AccountOpening.vue')

    },

    {
        path: '/accountspecimen',
        name: 'accountspecimen',
        component: () => import('../pages/CustomerSpecimen.vue')

    },

    {
        path: '/loanapplication',
        name: 'loanapplication',
        component: () => import('../pages/LoanApplication.vue')

    },

    {
        path: '/loanapproval',
        name: 'loanapproval',
        component: () => import('../pages/LoanApprovals.vue')

    },

    {
        path: '/loanrepayment',
        name: 'loanrepayment',
        component: () => import('../pages/LoanRepayment.vue')

    },
    {
        path: '/transactions',
        name: 'transactions',
        component: () => import('../pages/TransactionsPage.vue')

    },
    {
        path: '/transactionhistory',
        name: 'transactionhistory',
        component: () => import('../pages/TransactionHistory.vue')

    },
    {
        path: '/manageuser',
        name: 'manageuser',
        component: () => import('../pages/MnanageUser.vue')

    },

    {
        path: '/managerole',
        name: 'managerole',
        component: () => import('../pages/CreateRole.vue')

    },

    {
        path: '/guarantor',
        name: 'guarantor',
        component: () => import('../pages/GuranatorForm.vue')

    },

    {
        path: '/loandue',
        name: 'loandue',
        component: () => import('../pages/LoanDefaulters.vue')

    },

    {
        path: '/mail',
        name: 'mail',
        component: () => import('../pages/MailAccountSetup.vue')

    },

    {
        path: '/changepassword',
        name: 'changepassword',
        component: () => import('../pages/ChangePassword.vue')

    },

    {
        path: '/notauthorized',
        name: 'notauthorized',
        component: () => import('../pages/NotAuhtorized.vue')

    },

    {
        path: '/realtime',
        name: 'realtime',
        component: () => import('../pages/RealTimeBalance.vue')

    },

    {
        path: '/reference',
        name: 'reference',
        component: () => import('../pages/TransactionReference.vue')

    },
    {
        path: '/registerisusu',
        name: 'registerisusu',
        component: () =>import('../pages/RegisterIsusu.vue')
    },
    {
        path: '/isusutransaction',
        name: 'isusutransaction',
        component: () => import('../pages/IsusuTransaction.vue')
    },
    {
        path: '/isusutransactionhistory',
        name: 'isusutransactionhistory',
        component: () => import('../pages/IsusuTransactionHistory.vue')
    },

    {
        path: '/print',
        name: 'print',
        component: () => import('../pages/PrintingPage.vue')
    },

    {
        path: '/maintenance',
        name: 'maintenance',
        component: () => import('../pages/AccountMiantenance.vue')
    },
    {
        path: '/realtimeproduct',
        name: 'realtimeproduct',
        component: () => import('../pages/RealtimeBalanceProduct.vue')
    },
    {
        path: '/accountupdate',
        name: 'accountupdate',
        component: () => import('../pages/AccountUpdate.vue')
    },
    {
        path: '/accountview',
        name: 'accountview',
        component: () => import('../pages/AccountView.vue')
    },

    {
        path: '/customers',
        name: 'customers',
        component: () => import('../pages/CustomerListing.vue')
    },

    {
        path: '/isusucharge',
        name: 'isusucharge',
        component: () => import('../pages/ChargingIsusu.vue')
    },

    {
        path: '/approvetransact',
        name: 'approvetransact',
        component: () => import('../pages/ApprovalPage.vue')
    },

    {
        path: '/saveforme',
        name: 'saveforme',
        component: () => import('../pages/SaveAccountOpening.vue')
    },

    {
        path: '/savetransaction',
        name: 'savetransaction',
        component: () => import('../pages/SavingAccountTransaction.vue')
    },

    {
        path: '/savecustomers',
        name: 'savecustomers',
        component: () => import('../pages/SaveForCustomer.vue')
    },

    {
        path: '/till',
        name: 'till',
        component: () => import('../pages/MyTills.vue')
    },

    {
        path: '/fundtill',
        name: 'fundtill',
        component: () => import('../pages/FundTill.vue')
    },

    {
        path: '/utility',
        name: 'utility',
        component: () => import('../pages/UtilityAccount.vue')
    },
    {
        path: '/productcustomers',
        name: 'productcustomers',
        component: () => import('../pages/ProductCustomers.vue')
    },
    {
        path: '/audit',
        name: 'audit',
        component: () => import('../pages/AuditAccounts.vue')
    },
    {
        path: '/rereversal',
        name: 'rereversal',
        component: () => import('../pages/ReservalRequest.vue')
    },

    {
        path: '/apreversal',
        name: 'apreversal',
        component: () => import('../pages/ReversalApprove.vue')
    },
    {
        path: '/fixedeposit',
        name: 'fixedeposit',
        component: () => import('../pages/FixedDeposit.vue')
    },

    {
        path: '/fixedapproval',
        name: 'fixedapproval',
        component: () => import('../pages/FixedApproval.vue')
    },

    {
        path: '/fixedlsit',
        name: 'fixedlist',
        component: () => import('../pages/FixedDepositList.vue')
    },


    {
        path: '/itransfer',
        name: 'itransfer',
        component: () => import('../pages/InterTransfers.vue')
    },


    {
        path: '/meatsavers',
        name: 'meatsavers',
        component: () => import('../pages/MeatSaving.vue')
    },

    {
        path: '/meatsaversd',
        name: 'meatsaversd',
        component: () => import('../pages/MeatSaving.vue')
    },

    {
        path: '/meattransaction',
        name: 'meattransaction',
        component: () => import('../pages/MeatTransaction.vue')
    },

    {
        path: '/meathistory',
        name: 'meathistory',
        component: () => import('../pages/MeatTransitionHistory.vue')
    },

    {
        path: '/meatcustomer',
        name: 'meatcustomer',
        component: () => import('../pages/MeatSavingCustomers.vue')
    },


  
]


const router = createRouter({

    history: createWebHistory(process.env.Base_url),
    routes

});

router.beforeEach((to, from, next) => {
    const p = window.localStorage.getItem("user");

    const xc = window.localStorage.getItem("datax");

    let qa = false;


    qa = false;

    if (xc) {

       

        const split = xc.split(',');

        for ( let i = 0; i < split.length; i++){

            if (split[i] == to.name) {

                qa = true;
            }
        }

       
    }

    if (to.name !== 'home' && p == "" && to.name !== "changepassword" && to.name !== "notauthorized" && qa === false && to.name !=="print") {
        next({ name: 'home' });
    }

    else if (qa === false && to.name !== 'home' && p !== "" && to.name !== "changepassword" && to.name !== "notauthorized" && to.name !== "print")
    {
        next({ name: 'notauthorized' });

      
    }

   
    else {
        next();
    }



   // else if (qa == false) { next({ name: to.name?.toString() }); }
})


export default router