

    import { defineComponent } from 'vue'


    export default defineComponent({

        data() {
            return {
                style1: {
                    width:"0px"
                },
                style2: {
                    marginleft:"0px"
                },
                tevent: true,
                open: false,

                user:''
            }
        },

        created() {

            setInterval(() => {
                this.check();

            },1000);
        },


        methods: {

            async check() {
                const p = window.localStorage.getItem("user");

                if (p != "") {
                    this.open = true;
                  
                }
               
                else {
                    this.open = false;
                }

                const pn = window.localStorage.getItem("name");
                if (pn != null) {

                    this.user = pn;
                }
                if (pn == null) {
                    this.user = "";
                    this.open = false;
                }
                //name
            },

            logout() {

                window.localStorage.SetItem("name", null);
                window.localStorage.clear();

                window.location.href = "/";
             
            },


            toggle() {

                if (this.tevent == false) {

                    this.style1.width = "0px";
                    this.style2.marginleft = "0px";
                    this.tevent = true;
                    return;
                }
                if (this.tevent == true) {

                    this.style1.width = "290px";
                    this.style2.marginleft = "290px";
                    this.tevent = false;
                    return;
                }

            }
        }
    });

